<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="24">
              <el-page-header title="返回" @back="goBack" content="修改密码"></el-page-header>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form class="type-form" :model="password" label-width="80px">
          <!-- <el-form-item label="旧密码:">
            <el-input
              v-model="password.old_password"
              placeholder="请输入旧密码"
              show-password
              clearable
            ></el-input>
          </el-form-item>-->
          <el-form-item label="新密码:" prop="name">
            <el-input v-model="password.new_password" placeholder="新密码" show-password clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="getRandomPassword" @click="getRandomPassword(8)" type="primary">生成随机密码</el-button>
            <el-button
              class="saveNewPassword"
              type="primary"
              style="width: 100px"
              @click="saveNewPassword"
            >保存</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "UpdateThePasswordView",
  data() {
    return {
      loading: false,
      password: {
        // old_password: "",
        new_password: ""
      }
    };
  },
  methods: {
    saveNewPassword() {
      //   if (this.password.old_password.length === 0) {
      //     this.$message.error('旧密码不能为空');
      //     return;
      //   }

      if (this.password.new_password.length === 0) {
        this.$message.error("新密码不能为空");
        return;
      }

      //   if (this.password.old_password === this.password.new_password) {
      //     this.$message.error('请不要设置与旧密码相同的新密码');
      //     return;
      //   }

      let _me = this;
      let params = {
        // old_password: this.$md5(this.password.old_password),
        password: this.$md5(this.password.new_password)
      };
      this.$common
        .httpPost("/api/user/admin/update/password", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("修改成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getRandomPassword(length) {
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
      this.password.new_password = password;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.saveNewPassword {
  float: right;
}
</style>
